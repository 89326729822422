import { useState } from "react";
import { api } from "../services/api";
import IntlCurrencyInput from "react-intl-currency-input";

import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfMonth, startOfMonth } from "date-fns";

interface ReportItem {
  amount: number;
  quantity: number;
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const people = [
  {
    id: "0245d095-1d8b-401f-8c40-7cd239ab58af",
    name: "MUNICIPIO DE SANTA LUZIA MG",
  },
  {
    id: "8059e2ea-4986-4abc-aa62-4df671435afa",
    name: "prefeitura municipal de governador valadares",
  },
  {
    id: "991327f9-3a92-461a-9c6f-3d7e694d8e35",
    name: "Fundo Municipal de Saude de Irituia",
  },
  {
    id: "271aadaf-c9d0-4514-894e-81a741f0dd44",
    name: "PREFEITURA MUNICIPAL DE BAYEUX",
  },
  {
    id: "7b537dfb-a885-4d67-8b5d-cd25bdcd5be1",
    name: "Prefeitura Municipal de São Francisco",
  },
  {
    id: "57ea8400-113a-449a-a292-d6e485a539ac",
    name: "MUNICIPIO DE SENADOR JOSE PORFIRIO",
  },
  {
    id: "38422aaf-207d-4b87-9f27-daf8a84ba6b2",
    name: "MUNICIPIO DE CONCEICAO DO ARAGUAIA",
  },
  {
    id: "6f4b49d0-215e-4ee5-9682-5892dc193073",
    name: "MUNICIPIO DE SANTA LUZIA DO PARA",
  },
  {
    id: "2556193f-136e-4510-8e30-b3f6a1fd81e7",
    name: "FUNDO MUNICIPAL DE MEIO AMBIENTE FMMA",
  },
  {
    id: "182ccafa-9953-4d48-a9c2-11d861e3da93",
    name: "SECRETARIA MUNICIPAL DE MEIO AMBIENTE",
  },
  {
    id: "c75d370d-e4fe-4e1d-b8be-ec4ba745e382",
    name: "FUNDO MUNICIPAL DE MEIO AMBIENTE",
  },
  {
    id: "29b95424-cda1-49f0-a793-614537c70938",
    name: "FUNDO MUNICIPAL DE DESENVOLVIMENTO RURAL SUSTENTAVEL",
  },
  {
    id: "585ef825-141d-48b5-93d3-58ce79d660d9",
    name: "FUNDO MUNICIPAL DE ECONOMIA SOLIDARIA FUNDOSOL",
  },
  {
    id: "fa0481ff-f396-4a1b-a262-01fdc5c9cded",
    name: "PREFEITURA MUNICIPAL DE ARENAPOLIS",
  },
  {
    id: "b4fe6345-3788-4066-9999-f58f63bd1217",
    name: "MUNICIPIO DE CAPITAO POCO",
  },
  { id: "3174eda1-5be5-44dc-9b5e-9591c1fe3cfe", name: "MUNICIPIO DE PLACAS" },
  {
    id: "5759aa2e-835f-4226-8976-648f27625e5c",
    name: "MUNICIPIO DE ITUPIRANGA",
  },
  {
    id: "8988d140-4789-4d44-a570-a25828c5fde2",
    name: "MUNICIPIO DE MAE DO RIO",
  },
  {
    id: "cdb08047-8161-46ca-aa87-0260634893fc",
    name: "PREFEITURA MUNICIPAL DE CUMARU DO NORTE PA",
  },
  { id: "1e140cdc-d44f-4300-8d39-c311f643ae49", name: "MUNICIPIO DE IRITUIA" },
  { id: "a072a40f-421e-44c0-8ee0-2e4934488391", name: "MUNICIPIO DE XINGUARA" },
  {
    id: "967c6a5d-6ac7-47f6-889c-e8831b6917c7",
    name: "PREFEITURA MUNICIPAL DE BREU BRANCO",
  },
  { id: "92a67ffe-d6e3-484f-b754-b98c8adfe867", name: "MUNICIPIO DE SOURE" },
  {
    id: "c38d841f-a884-483b-8344-a13120ebdcd8",
    name: "PREFEITURA MUNICIPAL DE PONTA DE PEDRAS",
  },
  {
    id: "405ca753-8ac9-444d-a7c7-f17be76cfefe",
    name: "PREFEITURA MUNICIPAL DE SANTA MARIA DAS BARREIRA - PARA",
  },
  {
    id: "f901c8a5-0920-43bd-a819-3463303acf3a",
    name: "MUNICIPIO DE IGARAPE MIRI",
  },
  {
    id: "89a31dfb-4a46-4226-8c2a-f6e115415e68",
    name: "PREFEITURA MUNICIPAL DE ELDORADO DO CARAJÁS",
  },
  {
    id: "7cfb42f3-167e-4572-9528-51c438828c37",
    name: "PREFEITURA MUNICIPAL DE MOCAJUBA",
  },
  {
    id: "1ce53cf5-348c-404e-8447-a98c5d058939",
    name: "MUNICIPIO DE PLACAS EVENTO",
  },
  {
    id: "efba4aae-6022-4aad-b5eb-5479de904bf0",
    name: "FUNDO MUNICIPAL DE SAÚDE",
  },
  {
    id: "b2117491-3333-4b9b-b062-ee29fc1289ad",
    name: "FUNDO MUNICIPAL DE MEIO AMBIENTE",
  },
  {
    id: "85e6972c-33a5-45f0-8358-c8047ac1aed3",
    name: "PREFEITURA MUNICIPAL DE ANAPU",
  },
  {
    id: "9b6daca1-d306-471c-a6d1-fff57cb0e978",
    name: "FUNDO MUNICIPAL DE MEIO AMBIENTE",
  },
  { id: "0fa48f1f-aa5e-4145-bf09-28fc33ec2a77", name: "Municipio de Salinas" },
];

export function Reports() {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [processamento, setProcessamento] = useState<ReportItem>();
  const [registro, setRegistro] = useState<ReportItem>();
  const [baixa, setBaixa] = useState<ReportItem>();
  const [pix, setPix] = useState<ReportItem>();
  const [ted, setTed] = useState<ReportItem>();
  const [cda, setCda] = useState<ReportItem>();

  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  async function generateReport() {
    try {
      setLoading(true);
      const { data } = await api.post("/backoffice/reports", {
        start: startDate,
        end: endDate,
        accountId: selectedPerson?.id || undefined,
      });

      setProcessamento(data?.report?.processamento);
      setRegistro(data?.report?.registro);
      setBaixa(data?.report?.baixa);
      setTed(data?.report?.ted);
      setPix(data?.report?.pix);
      setCda(data?.report?.cda);
    } catch (err) {
      // toast.error('Erro ao gerar relatório');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-semibold text-gray-900">Relatórios</h1>
        <div className="mt-4 flex flex-col"></div>
        <div className="mb-4">
          <h1>Selecione o período</h1>
          <div className="flex w-40">
            <DatePicker
              className="bg-gray-300 rounded px-2 py-1"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              locale="pt"
              dateFormat="dd/MM/yyyy"
            />
            <span className="px-2 py-1">até</span>
            <DatePicker
              className="bg-gray-300 rounded px-2 py-1"
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              locale="pt"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <Combobox
          as="div"
          value={selectedPerson}
          onChange={(person) => {
            setQuery("");
            setSelectedPerson(person);
          }}
        >
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Selecione Conta (Opcional)
          </label>
          <div className="relative mt-2 ">
            <Combobox.Input
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(event) => setQuery(event.target.value)}
              onBlur={() => setQuery("")}
              displayValue={(person: { id: string; name: string }) =>
                person?.name
              }
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>

            {filteredPeople.length > 0 && (
              <Combobox.Options className="absolute z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    value={person}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                  >
                    <span className="block truncate group-data-[selected]:font-semibold">
                      {person.name}
                    </span>

                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 relative top-4">
        <div>
          <button
            type="button"
            className="mt-0 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 "
            onClick={generateReport}
          >
            Gerar Relatório
          </button>
        </div>

        <div className="mt-4">
          {loading && <span>Gerando relatório...</span>}
          {!loading && pix && (
            <div className="mb-16">
              <div className="mt-4 pb-4 border-b border-gray-300">
                <h2
                  id="payment-details-heading"
                  className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                >
                  Processamento Eletronico de boleto
                </h2>
                <div className="mt-4 grid grid-cols-6 gap-6">
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantidade
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      // currency="BRL"
                      // config={currencyConfig}
                      value={processamento?.quantity}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Valor total
                    </label>
                    <IntlCurrencyInput
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      currency="BRL"
                      config={currencyConfig}
                      value={processamento?.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 pb-4 border-b border-gray-300">
                <h2
                  id="payment-details-heading"
                  className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                >
                  Registro e Liquidacao de boletos
                </h2>
                <div className="mt-4 grid grid-cols-6 gap-6">
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantidade
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      // currency="BRL"
                      // config={currencyConfig}
                      value={registro?.quantity}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Valor total
                    </label>
                    <IntlCurrencyInput
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      currency="BRL"
                      config={currencyConfig}
                      value={registro?.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 pb-4 border-b border-gray-300">
                <h2
                  id="payment-details-heading"
                  className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                >
                  Processamento Dívida Ativa(CDA)
                </h2>
                <div className="mt-4 grid grid-cols-6 gap-6">
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantidade
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      // currency="BRL"
                      // config={currencyConfig}
                      value={cda?.quantity}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Valor total
                    </label>
                    <IntlCurrencyInput
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      currency="BRL"
                      config={currencyConfig}
                      value={cda?.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 pb-4 border-b border-gray-300">
                <h2
                  id="payment-details-heading"
                  className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                >
                  Baixa de Boletos
                </h2>
                <div className="mt-4 grid grid-cols-6 gap-6">
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantidade
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      // currency="BRL"
                      // config={currencyConfig}
                      value={baixa?.quantity}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Valor total
                    </label>
                    <IntlCurrencyInput
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      currency="BRL"
                      config={currencyConfig}
                      value={baixa?.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 pb-4 border-b border-gray-300">
                <h2
                  id="payment-details-heading"
                  className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                >
                  TED
                </h2>
                <div className="mt-4 grid grid-cols-6 gap-6">
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantidade
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      // currency="BRL"
                      // config={currencyConfig}
                      value={ted?.quantity}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Valor total
                    </label>
                    <IntlCurrencyInput
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      currency="BRL"
                      config={currencyConfig}
                      value={ted?.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <h2
                  id="payment-details-heading"
                  className="flex justify-between text-lg leading-6 font-medium text-gray-900"
                >
                  Pix
                </h2>
                <div className="mt-4 grid grid-cols-6 gap-6">
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantidade
                    </label>
                    <input
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      // currency="BRL"
                      // config={currencyConfig}
                      value={pix?.quantity}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Valor total
                    </label>
                    <IntlCurrencyInput
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      currency="BRL"
                      config={currencyConfig}
                      value={pix?.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
